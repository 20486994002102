import { useRouter } from "next/router";
import React, { useContext } from "react";

import { ArticlesFeedContext } from "../../contexts/ArticlesFeedContext";

export function FeedFooter() {
  const router = useRouter();
  const currentPage = (router.query.page && Number(router.query.page)) || 1;
  const nextPageUrl = `${router.pathname}?page=${currentPage + 1}`;
  const { nextPage } = useContext(ArticlesFeedContext);

  return (
    <div className="feed-paging clearfix">
      <a
        className="feed-paging-next a a--primary"
        href={nextPageUrl}
        onClick={e => {
          e.preventDefault();
          nextPage();
        }}
      >
        Nästa sida &raquo;
      </a>
    </div>
  );
}
