import React from "react";
import Image from "next/image";

import type { Decoration } from "../../../client/ContentApi/models";
import type { AdRuleType } from "../../../client";
import type { AstNative } from "@omni/ads";

import { getDecorationProperties } from "../../../client/helpers";
import { CogwheelButton } from "../../Modal/buttons/CoghwheelButton";
import { getImageSizes } from "../../../helpers";
import { useCookiePermission } from "../../../hooks/cookiePermission/useCookiePermission";

const sizes = getImageSizes({ web: "80vw" });

type NativeAdComponentProps = {
  ad: AstNative;
  annons_bar_decoration: Decoration;
  native_ad_decoration: Decoration;
  ad_layout: "Small" | "Large";
  adRule: AdRuleType;
  creativeId?: number;
};

const NativeAdComponent: React.FC<NativeAdComponentProps> = ({
  ad,
  native_ad_decoration,
  annons_bar_decoration,
  adRule,
  ad_layout = "Large",
  creativeId
}) => {
  const advertisingConsent = useCookiePermission("CMP:advertising");
  const adClicked = () => ad.clickTrackers.map(ct => fetch(ct));
  const layout = ad_layout.toLowerCase();

  return (
    <article
      className="article article--compact article--fadehr article--native-ad"
      role="presentation"
      style={getDecorationProperties(native_ad_decoration)}
    >
      <a
        href={ad.clickUrl}
        onClick={() => {
          if (advertisingConsent) {
            adClicked();
          }
        }}
        className="article-link"
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="pre-banner resource--vignette resource--vignette-annons fs--smaller"
          style={getDecorationProperties(annons_bar_decoration)}
        >
          {adRule?.rules?.label || "ANNONS"}
          <div className="">
            <CogwheelButton creativeId={creativeId} />
          </div>
        </div>
        <div className={`teaser teaser--${layout}`}>
          <div
            role="button"
            className="resource resource--image resource--image--native-ad"
          >
            <div data-watermark="false" className="resource-img">
              <Image
                src={ad.image.url}
                title=""
                loading="lazy"
                alt=""
                className=""
                sizes={sizes}
                width={0}
                height={0}
              />
            </div>
          </div>
          <h1
            role="presentation"
            className={`resource resource--title resource--title--${layout} fs--h1`}
          >
            {ad.title}
          </h1>
          <div role="presentation" className="resource resource--text">
            <p>{ad.body}</p>
          </div>
          <div className="resource native-ad-from">
            {`Extern länk | ${ad.sponsoredBy}`}
          </div>
        </div>
      </a>
    </article>
  );
};

export { NativeAdComponent };
