import React from "react";

import type { CategoryLink } from "../../../client";

import { LinkToCategory } from "../../Link";

export function CategoryLinkFeedItem({
  item: { category }
}: {
  item: CategoryLink;
}) {
  return (
    <LinkToCategory
      category={category}
      className={`component component--categoryLink component--categoryLink--${category.slug}`}
    >
      Mer {category.title}
    </LinkToCategory>
  );
}
