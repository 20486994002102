import React, { useState, useCallback } from "react";

import type { NewsfeedAdPlaceholder } from "../../../client/ContentApi/models/newsfeedAdPlaceholder";
import type { AdRuleType } from "../../../client";
import type { AstAdObj, AstNative } from "@omni/ads";

import { NativeAdComponent } from "./NativeAd";
import { Ad } from "../../Ad";
import { CogwheelButton } from "../../Modal/buttons/CoghwheelButton";

const failureCallback = (label: string) => (ad: any) => {
  console.error(label, ad);
};

export function NewsfeedAdPlaceholderFeedItem({
  item
}: {
  item: NewsfeedAdPlaceholder;
}) {
  const { native_ad_decoration, annons_bar_decoration, native_ad_layout } =
    item;

  const [creativeId, setCreativeId] = useState<number>();
  const onFailure = useCallback((e?: AstAdObj | null) => {
    failureCallback("FAIL")(e);
    setHide(true);
  }, []);

  const [nativeAd, setNativeAd] = useState<{
    ad: AstNative;
    adRule: AdRuleType;
  }>();
  const [hide, setHide] = useState<boolean>(false);

  const onAdLoaded = useCallback(
    (someAd: AstAdObj, adRule: AdRuleType) => {
      switch (someAd.adType) {
        case "native":
          if (someAd?.native) {
            setCreativeId(someAd?.creativeId);
            setNativeAd({ ad: someAd.native, adRule });
          }
          break;
        case "banner":
          setCreativeId(someAd?.creativeId);

          if (someAd.targetId) {
            const subContainer = document.querySelector(
              `#div_utif_${someAd.targetId}`
            ) as HTMLDivElement;
            subContainer.style.width = "100%";
          }
          break;
        default:
          failureCallback("adLoaded")(someAd);
      }
    },
    [setNativeAd]
  );

  if (hide) {
    return null;
  }

  if (nativeAd) {
    return (
      <div id="newsfeed">
        <NativeAdComponent
          {...nativeAd}
          annons_bar_decoration={annons_bar_decoration}
          native_ad_decoration={native_ad_decoration}
          ad_layout={native_ad_layout}
          creativeId={creativeId}
        />
      </div>
    );
  }

  return (
    <Ad
      id="newsfeed"
      className="native-ad-banner"
      placement="newsfeed"
      onFailure={onFailure}
      onLoaded={onAdLoaded}
      skipPre={true}
    >
      <div className="pre-banner native-ad-banner-label">
        <div className="pre-banner-left-bigger">Annons</div>
        <div className="pre-banner-right">
          <CogwheelButton creativeId={creativeId} />
        </div>
      </div>
    </Ad>
  );
}
