import React from "react";

import type { ArticleCarousel } from "../../../client";

import { ArticleFeedItem } from "../ArticleFeedItem";
import { isArticle } from "../../../helpers";

export function ArticleCarouselFeedItem({ item }: { item: ArticleCarousel }) {
  return (
    <>
      {item.articles.map((article, i) =>
        isArticle(article) ? (
          <div key={i} className="component--categoryCarousel">
            <ArticleFeedItem item={article} index={0} isNotExpandable={false} />
          </div>
        ) : null
      )}
    </>
  );
}
