import React from "react";

import type { BoxButton } from "../../../client/ContentApi/models/boxbutton";

import { LinkToCategory } from "../../Link";

export function BoxButtonFeedItem({ item }: { item: BoxButton }) {
  const { category } = item;

  return (
    <div className="component--box_button">
      <LinkToCategory category={category}>
        <button className="btn--light btn btn--secondary btn--secondary-premium btn--wide">
          {category?.title}
        </button>
      </LinkToCategory>
    </div>
  );
}
