import type { ReactNode } from "react";
import type { Box } from "../../../client/ContentApi/models/box";
import type { Article, Topic, Category } from "../../../client";

import { getDecorationProperties } from "../../../client";
import { LinkToCategory, LinkToTopic } from "../../Link";
import { ArticleFeedItem } from "../ArticleFeedItem";
import { getCategory } from "../FeedHeader";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

function getData(box: Box, data: string) {
  return box.header.find(item => item.type === data);
}

function getTopic(topic_id: string, articles: Article[]) {
  return articles
    .reduce((topics: Topic[], article) => {
      if (article.story) {
        return topics.concat(article.tags).concat(article.story);
      }

      return topics.concat(article.tags);
    }, [])
    .find(topic => topic && topic.topic_id === topic_id);
}

function ReadMoreButton({
  topic,
  category,
  children
}: {
  topic?: Topic;
  category?: Category;
  children: ReactNode;
}) {
  if (category) {
    return <LinkToCategory category={category}>{children}</LinkToCategory>;
  }

  if (topic) {
    return (
      <LinkToTopic
        topic={{
          title: topic?.title as string,
          topic_id: topic?.topic_id as string
        }}
      >
        {children}
      </LinkToTopic>
    );
  }

  return null;
}

function BoxHeader({ item }: { item: Box }) {
  const overline = getData(item, "Overline");
  const title = getData(item, "Title");
  const intro = getData(item, "Intro");

  if (!overline && !title && !intro) {
    return null;
  }

  return (
    <div className="component--dynamic_box_heading">
      {overline ? (
        <div
          style={getDecorationProperties(overline?.decoration)}
          className="component--dynamic_box_heading--kicker"
        >
          {overline.value}
        </div>
      ) : null}
      {title ? (
        <h3
          style={getDecorationProperties(title?.decoration)}
          className="component--dynamic_box_heading--title"
        >
          {title.value}
        </h3>
      ) : null}
      {intro ? (
        <div
          style={getDecorationProperties(intro?.decoration)}
          className="component--dynamic_box_heading--text"
        >
          {intro.value}
        </div>
      ) : null}
    </div>
  );
}

function BoxFooter({ item }: { item: Box }) {
  let category, topic, linkText;
  const { allCategories } = useGlobalStore();
  const footer = item.footer[0];

  if (footer) {
    if (footer.link.category_id) {
      category = getCategory(footer.link.slug as string, allCategories);
      linkText = footer.value || category?.title;
    } else if (footer.link.topic_id) {
      topic = getTopic(footer.link.topic_id, item.articles);
      linkText = footer.value || topic?.title;
    }

    return (
      <div className="component--dynamic_box_button">
        <ReadMoreButton topic={topic} category={category}>
          <button
            style={getDecorationProperties(footer.decoration)}
            className="btn--light btn btn--secondary btn--wide"
          >
            {linkText}
          </button>
        </ReadMoreButton>
      </div>
    );
  }

  return null;
}

export function BoxFeedItem({ item }: { item: Box }) {
  return (
    <>
      <BoxHeader item={item} />
      {item.articles.map((article, i) =>
        article.type === "Article" ? (
          <ArticleFeedItem
            item={article}
            index={i}
            key={i}
            isNotExpandable={true}
          />
        ) : null
      )}
      <BoxFooter item={item} />
    </>
  );
}
