import throttle from "lodash.throttle";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";

import type { AdRuleType, Article, Component, ViewType } from "../../client";
import type { BoxHeading } from "../../client/ContentApi/models/boxheading";
import type { CustomGroup } from "../../contexts/TeaserGroupContext";
import type { LayoutType } from "../../layout/Layout";

import { isThemeBox } from "../../client/helpers";
import { ArticlesFeedContext } from "../../contexts/ArticlesFeedContext";
import { TeaserGroupContext } from "../../contexts/TeaserGroupContext";
import { TrackingContext } from "../../contexts/TrackingContext";
import { checkIfPassion } from "../../helpers";
import { useEventOnView } from "../../hooks/useEventOnView";
import { getPosition } from "../Article/helpers";
import { ArticleCarouselFeedItem } from "./ArticleCarouselFeedItem";
import { ArticleFeedItem } from "./ArticleFeedItem";
import { BoxButtonFeedItem } from "./BoxButtonFeedItem";
import { BoxHeadingFeedItem } from "./BoxHeadingFeedItem";
import { CategoryHeadingFeedItem } from "./CategoryHeadingFeedItem";
import { CategoryLinkFeedItem } from "./CategoryLinkFeedItem";
import { FeedFooter } from "./FeedFooter";
import { FeedHeader } from "./FeedHeader";
import { NewsfeedAdPlaceholderFeedItem } from "./NewsfeedAdPlaceholderFeedItem";
import { StickySponsorHeadingItem } from "./SponsorHeadingFeedItem";
import { StoryHeadingFeedItem } from "./StoryHeadingFeedItem";
import { StoryLinkFeedItem } from "./StoryLinkFeedItem";
import { BoxFeedItem } from "./BoxFeedItem";
import { getConfig } from "../../config";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";
import { FullscreenBanner } from "../Ad/components/FullscreenScrollAd";

interface FeedItemProps {
  item: Component;
  index?: number;
  isNotExpandable?: boolean;
  firstArticleId?: string;
  isPremium?: boolean;
}

const checkIfFlash = (value: string | undefined) => {
  return value === "Flash" ? "--flash" : "";
};

function isScrolledNearToBottom() {
  const scrollTop =
    window.scrollY ||
    window.document.body.scrollTop ||
    window.document.documentElement.scrollTop;
  const windowHeight = window.innerHeight;
  const documentHeight = document.body.clientHeight;

  return documentHeight - scrollTop - windowHeight < windowHeight;
}

function getFullscreenBannerPosition(adRules: AdRuleType[] | undefined) {
  if (adRules) {
    try {
      const adRule = adRules.find(
        adRule => adRule.rules.position === "fullscreen"
      );

      if (adRule) {
        return adRule.rules.index;
      }
    } catch (e) {
      console.error("Error in getFullscreenBannerPosition", e);
      return undefined;
    }
  }

  return undefined;
}

export function Feed({ type }: { type?: LayoutType }) {
  const { articles, nextPage, hasMore } = useContext(ArticlesFeedContext);
  const { adRules } = useGlobalStore();
  const [view, setView] = useState<ViewType>();
  const router = useRouter();

  useEffect(() => {
    switch (router.pathname) {
      case "/":
        return setView("top");
      case "/category":
        return setView("category");
      case "/topic":
        return setView("topic");
      case "/latest":
        return setView("latest");
      case "/article":
        return setView("top");
      default:
        return setView(undefined);
    }
  }, [router.pathname]);

  const isLoadingMore = useRef(false);

  useEffect(() => {
    const loadMoreOnScroll = throttle(async () => {
      if (!isScrolledNearToBottom() || !hasMore) {
        return;
      }

      if (isLoadingMore.current) {
        return;
      }
      isLoadingMore.current = true;
      try {
        nextPage();
      } finally {
        isLoadingMore.current = false;
      }
    }, 200);
    window.addEventListener("scroll", loadMoreOnScroll);

    return () => {
      window.removeEventListener("scroll", loadMoreOnScroll);
    };
  }, [nextPage, hasMore]);
  let count = 0;
  const fullscreenBannerPosition = getFullscreenBannerPosition(adRules);

  const { pre, ad, post } = articles
    .map((group, index) => {
      const ret = (
        <FeedGroup key={index} group={group} offset={count} view={view} />
      );
      count += group.length;
      return ret;
    })
    .reduce(
      (acc, el, index) => {
        if (index === fullscreenBannerPosition) {
          acc.ad = <FullscreenBanner />;
        }
        acc[acc.ad ? "post" : "pre"].push(el);
        return acc;
      },
      { pre: [], ad: null, post: [] } as {
        pre: JSX.Element[];
        post: JSX.Element[];
        ad: JSX.Element | null;
      }
    );

  return (
    <>
      {view && <StickySponsorHeadingItem view={view} />}
      <main role="main" className="feed">
        <FeedHeader />
        <div
          className={`feed--partial ${
            type ? `feed--partial--${type}` : ""
          } feed--partial__pre`}
        >
          {pre}
        </div>
        {ad}
        <div
          className={`feed--partial ${
            type ? `feed--partial--${type}` : ""
          } feed--partial__post`}
        >
          {post}
        </div>
        <FeedFooter />
      </main>
    </>
  );
}

const typesToComponentMap = {
  Article: ArticleFeedItem,
  StoryLink: StoryLinkFeedItem,
  StoryHeading: StoryHeadingFeedItem,
  CategoryHeading: CategoryHeadingFeedItem,
  ArticleCarousel: ArticleCarouselFeedItem,
  CategoryLink: CategoryLinkFeedItem,
  NewsfeedAdPlaceholder: NewsfeedAdPlaceholderFeedItem,
  BoxHeading: BoxHeadingFeedItem,
  BoxButton: BoxButtonFeedItem,
  Box: BoxFeedItem
} as {
  [key: string]: React.FC<FeedItemProps>;
};

const getArticleId = (article: Article | undefined) => {
  if (article === undefined) {
    return undefined;
  }

  return article.article_id;
};

export function FeedGroup({
  group,
  offset,
  isNotExpandable = false,
  view
}: {
  group: CustomGroup;
  offset?: number;
  isNotExpandable?: boolean;
  view?: ViewType;
}) {
  const filteredItems = group.filter(item => item.type in typesToComponentMap);
  const { themeBoxImpression } = useContext(TrackingContext);
  const { articles } = useContext(ArticlesFeedContext);
  const firstArticleInGroup = group.find(a => a.type === "Article");
  const firstArticleIndex = group.findIndex(a => a === firstArticleInGroup);
  const { NEXT_PUBLIC_NEWSROOM } = getConfig();
  const { handleRef } = useEventOnView(
    () => {
      if (isThemeBox(group) && firstArticleInGroup) {
        themeBoxImpression(
          group[0] as BoxHeading,
          getPosition(firstArticleInGroup as Article, articles) -
            firstArticleIndex
        );
      }
    },
    {
      threshold: 2 / 3
    }
  );

  if (filteredItems.length === 0) {
    return null;
  }

  const firstArticle = group.find(component => {
    const article = component as Article;
    if (article.article_id) {
      return true;
    }

    return false;
  }) as Article;
  const isNativeAd = group.length === 1 && firstArticle?.sponsor;
  const isPassion = checkIfPassion(firstArticle?.teaser_layout);
  const isFlash = checkIfFlash(firstArticle?.teaser_layout);
  const isDynamicBox = group[0]?.type === "Box";
  const clusterOrSingle =
    filteredItems.length === 1 && !isDynamicBox ? "single" : "cluster";

  const nativeAdClass = isNativeAd ? "group--sponsored" : "";
  const passionClass = isPassion ? "group--passion" : "";
  const clusterOrSingleClass = `group--${clusterOrSingle}${isFlash}`;
  const themeBoxClass =
    filteredItems[0].type === "BoxHeading" ? "group--themebox" : "";
  const dynamicBoxClass = isDynamicBox ? "group--dynamicbox" : "";
  const expandableClass = isNotExpandable
    ? "group--subscription u-fadehr--after"
    : view !== "latest"
    ? "shadow-ambient"
    : "";

  return (
    <div
      ref={handleRef}
      className={[
        "group",
        clusterOrSingleClass,
        nativeAdClass,
        passionClass,
        expandableClass,
        themeBoxClass,
        dynamicBoxClass
      ].join(" ")}
    >
      {filteredItems.map((item, i) => {
        const ItemComponent = typesToComponentMap[item.type];
        return (
          <TeaserGroupContext.Provider key={i} value={{ group }}>
            <ItemComponent
              isNotExpandable={isNotExpandable}
              item={item}
              index={offset ? offset + i : i}
              firstArticleId={getArticleId(firstArticle)}
              isPremium={
                firstArticle?.is_premium && NEXT_PUBLIC_NEWSROOM === "omni"
              }
            />
          </TeaserGroupContext.Provider>
        );
      })}
    </div>
  );
}
