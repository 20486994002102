import React, { useEffect, useRef, useState } from "react";
import {
  init,
  type DataControllerSdkOptions
} from "@schibsted/data-controller";

import { getConfig } from "../../config";

const DataController: React.FC = () => {
  const {
    translation: { application_name }
  } = getConfig();

  const [rendered, setRendered] = useState(false);
  const dataControllerRef = useRef(null);

  useEffect(() => {
    if (rendered) {
      return;
    }

    const options: DataControllerSdkOptions = {
      brandName: application_name,
      language: "sv",
      layout: "subheader"
    };

    const sdkInstance = init(options);
    sdkInstance.render(dataControllerRef.current);

    setRendered(true);
  }, [application_name, rendered]);

  return (
    <div className="datacontroller-container" ref={dataControllerRef}></div>
  );
};

const DataControllerFooter = () => (
  <p className="datacontroller--footer">
    Schibsted Media ansvarar för hanteringen av din data på den här webbplatsen.
  </p>
);

export { DataController, DataControllerFooter };
