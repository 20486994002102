import React, { useContext, useState } from "react";

import type { StoryHeading } from "../../../client";

import { getDecorationProperties } from "../../../client";
import { LinkToTopic } from "../../Link";
import { StarBox } from "../../StarBox";
import { ActiveArticleContext } from "../../../contexts/ActiveArticleContext";

const Suffix = ({
  suffix = "",
  suffix_separator = ""
}: {
  suffix?: string;
  suffix_separator?: string;
}) => (
  <span className="component--storyHeading-sponsoredcluster--suffix">
    {suffix_separator}
    {suffix}
  </span>
);

export function StoryHeadingFeedItem({
  item: { topic, supertag, newsfeed_decoration, suffix, suffix_separator },
  firstArticleId,
  isPremium
}: {
  item: StoryHeading;
  firstArticleId: string;
  isPremium: boolean;
}) {
  const { article_ids } = useContext(ActiveArticleContext);
  const [open, setOpen] = useState(false);

  if (article_ids.includes(firstArticleId) && open === false) {
    setOpen(true);
  } else if (!article_ids.includes(firstArticleId) && open === true) {
    setOpen(false);
  }

  if (suffix && suffix_separator) {
    return (
      <div
        className={
          "component component--storyHeading" +
          (open ? " component--storyHeading--full" : "")
        }
        style={
          isPremium
            ? getDecorationProperties(newsfeed_decoration, {
                "--primary": "var(--premium--primary)"
              })
            : getDecorationProperties(newsfeed_decoration)
        }
      >
        <div className="component--storyHeading-sponsoredcluster--title">
          <LinkToTopic
            style={getDecorationProperties(newsfeed_decoration)}
            topic={topic}
          >
            {topic.title}
          </LinkToTopic>
          <Suffix suffix={suffix} suffix_separator={suffix_separator} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        "component component--storyHeading" +
        (open ? " component--storyHeading--full" : "")
      }
      style={
        isPremium
          ? getDecorationProperties(newsfeed_decoration, {
              "--primary": "var(--premium--primary)"
            })
          : getDecorationProperties(newsfeed_decoration)
      }
    >
      <div className="component--storyHeading-container--title">
        <LinkToTopic
          style={getDecorationProperties(newsfeed_decoration)}
          topic={supertag || topic}
        >
          {supertag ? supertag.title : topic.title}
        </LinkToTopic>
        {supertag && (
          <>
            <span className="component--storyHeading--separator">|</span>
            <LinkToTopic topic={topic}>
              <span className="component--storyHeading-story">
                {topic.title}
              </span>
            </LinkToTopic>
          </>
        )}
      </div>

      <div
        className="component--storyHeading-container--star"
        style={getDecorationProperties(newsfeed_decoration)}
      >
        <StarBox topic={supertag || topic} />
      </div>
    </div>
  );
}
