import React from "react";

import type { StoryLink } from "../../../client";

import { LinkToTopic } from "../../Link";
import { getDecorationProperties } from "../../../client/helpers/index";

export function StoryLinkFeedItem({
  item: { supertag, topic, newsfeed_decoration }
}: {
  item: StoryLink;
}) {
  return (
    <LinkToTopic
      topic={supertag || topic}
      style={getDecorationProperties(newsfeed_decoration)}
      className="component component--storyLink"
    >
      Läs mer om {supertag ? supertag.title : topic.title}
    </LinkToTopic>
  );
}
