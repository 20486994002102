import React, { useContext, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

import type { AdRuleType, ViewType } from "../../../client";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getCategory } from "../FeedHeader";
import { getAllowedAds } from "../../Ad/utils/ads/functions";
import { useGlobalStore } from "../../../containers/GlobalStateContainer/store";

type StickySponsorHeadingItemParams = {
  view: ViewType;
};

const StickySponsorHeadingItem: React.FC<StickySponsorHeadingItemParams> = ({
  view
}: StickySponsorHeadingItemParams) => {
  const {
    subscriptionsInfo: { isAdFree },
    categories,
    adRules
  } = useGlobalStore();
  const { article } = useContext(ArticleContext);
  const [stickySponsor, setStickySponsor] = useState<AdRuleType>();
  const [isDisplayed, setDisplayed] = useState<boolean>(false);

  const router = useRouter();

  const viewFilter = useCallback(
    (rule: AdRuleType) => {
      switch (view) {
        case "topic": {
          return getAllowedAds({ topics: [router.query.topicId as string] })(
            rule
          );
        }

        case "category": {
          const category = getCategory(router.query.slug as string, categories)
            ?.category_id;

          return getAllowedAds({ category })(rule);
        }

        case "article": {
          if (isDisplayed) {
            return false;
          }

          return getAllowedAds({
            topics: [article.story?.topic_id as string],
            category: article.category?.category_id
          })(rule);
        }

        default: {
          return true;
        }
      }
    },
    [view, router, article, categories, isDisplayed]
  );

  useEffect(() => {
    if (view !== "article" && stickySponsor) {
      setDisplayed(true);
    }

    try {
      setStickySponsor(
        adRules
          .filter(rule => rule.rules.position === "stickyTop")
          .filter(rule => rule.type === "SponsorBanner")
          .filter(rule => rule.rules.views?.includes(view))
          .filter(viewFilter)[0]
      );
    } catch (error) {
      console.error("setStickySponsor failed", error);
    }
  }, [viewFilter, adRules, view, stickySponsor]);

  if (!stickySponsor?.sponsor_data || isAdFree) {
    return null;
  }

  const { sponsor_data } = stickySponsor;
  const { red, green, blue } = sponsor_data?.color || {};

  const style = {
    "--background_primary": `rgb(${red},${green},${blue})`,
    "--background_image": `url(${sponsor_data.image_url})`
  } as React.CSSProperties;

  return (
    <a href={sponsor_data.link_url} rel="noreferrer" target="_blank">
      <div
        className="component component--sticky-sponsor-banner"
        style={style}
      />
    </a>
  );
};

export { StickySponsorHeadingItem };
