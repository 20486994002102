import React from "react";

import type { CategoryHeading } from "../../../client";

import { LinkToCategory } from "../../Link";

export function CategoryHeadingFeedItem({
  item: { category }
}: {
  item: CategoryHeading;
}) {
  return (
    <LinkToCategory
      category={category}
      className={`component component--categoryHeading feed-header feed-header--category feed-header--${category.slug}`}
    >
      {category.title}
    </LinkToCategory>
  );
}
