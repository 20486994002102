import React from "react";

import type { BoxHeading } from "../../../client/ContentApi/models/boxheading";

import { Markup } from "../../Article/Resources/TextResource/Markup";

export function BoxHeadingFeedItem({ item }: { item: BoxHeading }) {
  const { title, lead } = item;

  return (
    <div className="component--box_heading">
      <h3 className="component--box_heading--title">{title}</h3>
      <div className="component--box_heading--text">
        <Markup text={lead?.text} />
      </div>
    </div>
  );
}
