import { useRouter } from "next/router";
import React, { useContext } from "react";

import type { ReactNode } from "react";
import type { NextRouter } from "next/router";
import type { Article, Category, Group } from "../../client";

import { ArticlesFeedContext } from "../../contexts/ArticlesFeedContext";
import { MenuIcon } from "../Header/LeftNav/Menu/MenuIcon";
import { StarBox } from "../StarBox";
import { useGlobalStore } from "../../containers/GlobalStateContainer/store";
import { getTopicTitleFromArticle } from "../../helpers/topicTitle";

type FeedHeaderConfig = {
  title: string;
  topicId: string;
  icon: ReactNode | null;
  star: boolean;
};

const titleObj: FeedHeaderConfig = {
  title: "",
  topicId: "",
  icon: "",
  star: false
};

const getHeaderTitle = (
  router: NextRouter,
  categories: Category[],
  articles: Group[]
): FeedHeaderConfig => {
  switch (router.route) {
    case "/category":
      const category = getCategory(router?.query?.slug || "", categories);
      return {
        ...titleObj,
        title: category?.title || "",
        icon: <MenuIcon category={category} />
      };

    case "/topic":
      if (articles.length > 0 && articles[0].length > 0) {
        const topic = articles[0][0] as Article;
        return {
          ...titleObj,
          topicId: topic?.story_vignette?.id || "",
          star: true,
          title:
            getTopicTitleFromArticle(topic, router.query.topicId as string) ||
            ""
        };
      }
      return titleObj;
    default:
      return titleObj;
  }
};

export const getCategory = (slug: string | string[], categories: Category[]) =>
  categories.find(category => category?.slug === slug);

export const FeedHeader = () => {
  const { categories } = useGlobalStore();
  const { articles } = useContext(ArticlesFeedContext);
  const router = useRouter();

  const { title, icon, star, topicId } = getHeaderTitle(
    router,
    categories,
    articles
  );

  return (
    <>
      {title ? (
        <h1
          className={`feed-header ${
            title ? `feed-header--${router.route.replace("/", "")}` : ""
          }`}
        >
          {icon && <div className="feed-header--icon">{icon}</div>}
          <span className="feed-header--title">{title}</span>
          {star && (
            <div className="feed-header--star">
              <StarBox topic={{ topic_id: topicId, title }} />
            </div>
          )}
        </h1>
      ) : null}
    </>
  );
};
