import React from "react";

import type { Article } from "../../../client";

import { ArticleContainer } from "../../../containers/ArticleContainer";
import { ArticleComponent } from "../../Article";

export function ArticleFeedItem({
  item,
  index = 0,
  isNotExpandable
}: {
  item: Article;
  index?: number;
  isNotExpandable?: boolean;
}) {
  return (
    <ArticleContainer article={item} lazy={index > 1}>
      <ArticleComponent isNotExpandable={isNotExpandable} type="feed" />
    </ArticleContainer>
  );
}
